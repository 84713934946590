import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout page="Not Found">
    <SEO title="404: Not found" />
    <section className="expanded" style={{ padding: "150px 0" }}>
      <div className="container">
        {/* <h1>NOT FOUND</h1> */}
        <h2>Page doesn&#39;t exist.</h2>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
